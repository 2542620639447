@import '../../../node_modules/animate.css/source/_base';
@import '../../../node_modules/animate.css/source/fading_entrances/fadeIn';
@import '../../../node_modules/animate.css/source/fading_entrances/fadeInLeft';
@import '../../../node_modules/animate.css/source/fading_exits/fadeOut';
@import '../../../node_modules/animate.css/source/fading_exits/fadeOutLeft';

// overwrite
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-20%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

// overwrite
@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-20%, 0, 0);
  }
}

.nav-container,
.nav--primary {
  animation-duration: .4s;
}
