@import '../../../node_modules/kirby-base-theme/src/scss/base/base';


html {
  height: 100%;
}
body {
  min-height: 100%;
}

.sticky-footer {
  display: flex;
  flex-flow: column nowrap;
  main {
    flex-grow: 1;
  }
}
