@import '../../../node_modules/kirby-base-theme/src/scss/base/grid';

@include media-query(screen-sm-max) {
  .container {
    @include padding-default($grid__gutter--xs--half);
  }
}

.container--left {
  margin-left: 0;
}

// DISPLAY HELPER CLASSES

// HIDE (max-width media query)
// hide on xs = hidden until xs-max
@include media-query(screen-xs-max) {
  .hide-xs { display: none !important; }
}

@include media-query(screen-sm-max) {
  .hide-sm { display: none !important; }
}

@include media-query(screen-md-max) {
  .hide-md { display: none !important; }
}

@include media-query(screen-lg-max) {
  .hide-lg { display: none !important; }
}

@include media-query(screen-xl-max) {
  .hide-xl { display: none !important; }
}


// HIDE FROM (min-width media query)
// hide from xs = hiddem fom xs-min until infinity
@include media-query(screen-xs) {
  .hide-from-xs { display: none !important; }
}

@include media-query(screen-sm) {
  .hide-from-sm { display: none !important; }
}

@include media-query(screen-md) {
  .hide-from-md { display: none !important; }
}

@include media-query(screen-lg) {
  .hide-from-lg { display: none !important; }
}

@include media-query(screen-xl) {
  .hide-from-xl { display: none !important; }
}

@include media-query(screen-xxl) {
  .hide-from-xxl { display: none !important; }
}
