@import '../../node_modules/kirby-base-theme/src/scss/variables';

$resize-base-font-size: false;

$menucolor: (
  magentaish: #F000F9,
  babyblueish: #78D8FF,
  yellowish: #FFE900,
  redish: #FF4B60,
  greenish: #00BE8D
);

$manifestocolor: (
  yellowish: #FFF43A,
  babyblueish: #AAEBFF,
  orangeish: #FFBE00,
  roseish: #FFD5F1,
  greenish: #77E5B2
);

$color-yellow: #FFF43A;
$color-page-background: #FAFAFA;
$color-links: #000;

$link-decoration: none;
$link-decoration-hover: underline;


$font-stack-sans: 'NeueHaas-Bold', Helvetica, Arial, sans-serif;
$base-font-family: $font-stack-sans;
$headings__font-family: $font-stack-sans;

// GRID
$wrapper-max: 52rem; //782px
$grid__gutter: 4rem;
$grid__gutter--half: ($grid__gutter / 2);
$grid__gutter--xs: 3rem;
$grid__gutter--xs--half: ($grid__gutter--xs / 2);


// LAYOUT
$base-spacing-unit: 0.9375rem;
