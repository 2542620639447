.shopify-buy__option-select-wrapper {
    border: 1px solid black;
    border-radius: 0;
    box-sizing: border-box;
    position: relative;
    background: transparent;
    overflow: hidden;
    vertical-align: bottom;

    // FIXME
    max-width: 480px;
  }
  
  .shopify-buy__select-icon {
    cursor: pointer;
    display: block;
    fill: black;
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -6px;
    pointer-events: none;
    width: 12px;
    height: 12px;
    vertical-align: middle;
  }
  
  .shopify-buy__option-select {
    & + & {
      margin-top: calc(#{$base-spacing-unit} / 2);
    }
  }
  
  .shopify-buy__option-select__label {
    display: block;
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 5px;
  }
  
  .shopify-buy__btn--parent {
    & .shopify-buy__option-select__label {
      cursor: pointer;
    }
  }
  
  .shopify-buy__option-select__select {
    font-size: inherit;
    padding: 7px 10px;
    padding-right: 32px;
    border: 0;
    width: 100%;
    background: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
  
    &::-ms-expand {
      display: none;
    }
  }
  
  .shopify-buy__btn--parent {
    & .shopify-buy__option-select__select {
      cursor: pointer;
    }
  }