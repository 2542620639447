.swiper {}

.rte {
  .swiper-container {
    margin-left: 0;
    margin-right: 0;
  }
  .swiper-container.swiper--small { max-width: 380px }
  .swiper-container.swiper--medium { max-width: 550px }
  .swiper-container.swiper--large { max-width: 780px }
  .swiper-slide {
    cursor: pointer;
  }
  .swiper-pagination {
    width: auto;
    padding: 1rem 1.25rem;
    position: absolute;
    top: 0;
    right: 0;
    bottom: auto;
    left: auto;
    font-size: 0.9375rem;
  }
}

// @include media-query(screen-md) {
//   .rte {
//     .swiper-container.swiper--small { width: 380px }
//     .swiper-container.swiper--medium { width: 550px }
//     .swiper-container.swiper--large { width: 780px }
//   }
// }
