.page-header,
.page-footer {
  @include padding-default($grid__gutter--xs--half);
}

// [1] force new block formatting context
// [2] compensate left padding
.page-header {
  overflow: hidden; // [1]
  padding-top: 5rem;
  padding-bottom: 3rem;
  .header-menu {
    display: flex;
    position: fixed;
    top: 2.5rem;
    left: $grid__gutter--xs--half;
    margin-left: -1rem; // [2]
  }
}
.page-title {
  margin: 0;
  font-size: 0.9375rem;
  font-weight: inherit;
}

.nav--secondary {
  margin-top: 2rem;
  margin-bottom: -2rem;
  font-size: 0.9375rem;
  line-height: 1.4;
}

.page-footer {
  padding-top: 2rem;
  padding-bottom: $grid__gutter--xs--half;
  font-size: 0.9375rem;
  line-height: 1.4;

  ul > li {
    display: block;
    margin-right: 1em;
  }
}

.page--home {
  .page-header {
    padding-top: 3rem;
  }
}


.page-footer {
  a {
    display: inline-block;
    padding-bottom: 2px;
    font-weight: inherit;
    text-decoration: none;
    &:hover {
      padding-bottom: 0;
      border-bottom: 2px solid black;
    }
  }
}

// conditionally show and hide elements from the footer
.page-footer--default {
  .brand,
  .email {
    display: none;
  }
  @include media-query(screen-md) {
    .brand {
      display: inline;
    }
  }
  @include media-query(screen-lg) {
    .email {
      display: inline;
    }
  }
}


.smiley {
  position: absolute;
  top: $base-spacing-unit--lg;
  right: $grid__gutter--xs--half;
  width: 4rem;
  height: 4rem;
}


@include media-query(screen-sm) {
  .smiley {
    width: 5rem;
    height: 5rem;
  }
}

@include media-query(screen-md) {
  .page-header,
  .page-footer {
    @include padding-default;
  }
  .page-footer {
    padding-bottom: $base-spacing-unit--lg;
    ul > li {
      display: inline;
    }
  }
  .nav--secondary {
    margin-top: 0;
    margin-bottom: 0;
    padding-right: 0;
    position: fixed;
    right: $base-spacing-unit--lg;
    bottom: $base-spacing-unit--lg;
  }
}




@include media-query(screen-md) {
  .page-header {
    padding-top: 3rem;
    .header-menu {
      left: 50%;
      transform: translate(-50%);
    }
  }
  .smiley {
    position: fixed;
    right: $base-spacing-unit--lg;
  }
}
