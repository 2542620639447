.header-menu {
  z-index: 10;
}
.smiley {
  z-index: 10;
}
.loading {
  z-index: 9;
}
.nav-container {
  z-index: 8;
}
.nav--secondary {
  z-index: 7;
}
