.rte {
  // h1,
  // h2,
  // h3,
  // h4,
  // h5,
  // h6 {}
  // img {}

  ul,
  ol,
  dl
  audio,
  object,
  video,
  figure,
  .video {
    margin-bottom: $base-spacing-unit;
  }

  p {
    margin-bottom: $base-spacing-unit;
  }

  p a,
  a {
    text-decoration: none;
    border-bottom: 2px solid black;
    &:hover {
      border-bottom: 0;
      padding-bottom: 1px;
    }
  }

  // ul,
  // ol,
  // dl {}
  // a {}
  // strong,
  // em,
  // mark {}

  p {
    // max-width: 44rem; // 780px
    font-size: 0.9375rem;
    line-height: 1.5;
  }

  .rte__text--large p {
    font-size: 1.5rem;
    line-height: 1.3;
  }

  .rte__text--small p {
    // like copy default
    font-size: 0.9375rem;
    line-height: 1.5;
  }

  .check {
    img { width: 1rem; }
  }


  @include media-query(screen-md) {
    ul,
    ol,
    dl
    audio,
    object,
    video,
    figure,
    .video {
      margin-bottom: 2.5rem;
    }
    p {
      font-size: 1.5rem;
      line-height: 1.3;
      margin-bottom: 2.5rem;
    }
    .rte__text--small p {
      font-size: 0.9375rem;
      line-height: 1.5;
    }
    .rte__text--large p {
      font-size: 2rem;
    }
    .check {
      img { width: 1.7rem; }
    }
  }
  @include media-query(screen-xl) {}
}
