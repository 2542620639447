@import '../../../node_modules/kirby-base-theme/src/scss/components/buttons';

a,
button {
    
    &.btn {
        border-radius: 0;
    }
    
    &.btn--yellow {
        background-color: $color-yellow;
        color: black;
        font-weight: bold;
        transition: background-color .3s ease;
        &:hover {
            background-color: rgba($color-yellow, 0.6);
        }
    }
}

a,
span,
button {
    &.btn[disabled] {
        opacity: 0.5;
    }
}

.order-btn[disabled] {
    background-color: transparent;
    &:hover {
        background-color: transparent;
    }
}