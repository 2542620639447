.shopify-buy-frame {}
.shopify-buy__product__variant-selectors {
    text-align: left;
}

.shopify-buy__product__title {
    margin: 0;
    margin-bottom: 1.5rem;
    font-size: 1rem;
}


.shopify-buy__product-img-wrapper,
.shopify-buy__product__variant-selectors {
    margin-bottom: 2.5rem;
}

.shopify-buy__product {
    margin-bottom: 4.5rem;
}

.shopify-buy__product__details {
    .shopify-buy__product__price,
    .shopify-buy__btn-wrapper {
        display: inline;
    }
}
.shopify-buy__product__price {
    margin-right: $base-spacing-unit--lg;
}


.shopify-buy__product__compare-price {
    position: relative;
    margin-left: 0.75rem;
    &:after {
        position: absolute;
        top: calc(50% - 2px);    
        right: 0;
        left: 0;
        content: '';
        display: block;
        width: 100%;
        height: 4px;
        background-color: $color-yellow;
    }
}

.shopify-buy__collection-pagination-button {
    display: none;
}

// Image
// [1] do not be on top of menu
.shopify-buy__img__pagination {
    @extend .swiper-pagination;
    z-index: 1; // [1]
}
.shopify-buy__product-img-wrapper {
    position: relative;
}
// show pointer only if there is more than one image
.shopify-buy__product-img-wrapper.has-many-images .shopify-buy__product__variant-img {
    cursor: pointer;
}



@include media-query(screen-md) {
    .shopify-buy__product__title,
    .shopify-buy__product__details {
        font-size: 1.5rem;
    }  
}