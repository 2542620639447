figure.placeholder {
  background-color: #EEEEEE;
}

img {
  transition: opacity .33s ease;
  &.lazyload,
  &.lazyloading {
    opacity: 0;
  }
  &.lazyloaded {
    opacity: 1;
  }
}

.mouseover-image-group {
  &:hover {
    img:last-child {
      opacity: 1;
    }
  }
  img {
    position: relative;
  }
  img:first-child {
    z-index: 1;
  }
  img:last-child {
    z-index: 2;
    opacity: 0;
  }
}