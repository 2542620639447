@import '../../../node_modules/kirby-base-theme/src/scss/mixins/general';

@mixin menuColors() {
  @each $colorName, $colorValue in $menucolor {
    .nav--#{$colorName} {
      color: $colorValue;
    }
  }
}

@mixin manifestoColors() {
  @each $colorName, $colorValue in $manifestocolor {
    .bg--#{$colorName} {
      background-color: $colorValue;
    }
  }
}
