@import '../../../node_modules/kirby-base-theme/src/scss/components/navs';

@include menuColors();

.nav--primary {
  @include padding-default($grid__gutter--xs--half);
  padding-bottom: $base-spacing-unit;
  overflow: hidden;
  font-size: 2rem;
  line-height: 1.325;
  .nav__item {
    margin-bottom: 0.75rem;
    line-height: 1;
  }
  .nav__link {
    position: relative;
    padding: 0;
    display: inline-block;
    color: inherit;
  }
  .nav__link:hover {
    color: black;
  }
}

.js .nav-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $color-page-background;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  .nav {
    margin-top: 9.3rem;
  }
}
.nav-container.is-hidden {
  visibility: hidden;
}
.new-project-tag {
  display: inline-block;
  margin-left: 0.5rem;
  position: relative;
  top: -1rem;
  color: black;
  font-size: 0.9375rem;
}
.nav-toggle {
  font-size: 0.9375rem;
  line-height: 1.4;
  span {
    display: block;
    border-bottom: 2px solid black;
  }
  &:hover span {
    padding-bottom: 2px;
    border-bottom: 0;
  }
  &:active,
  &:focus {
    outline: 0; // TODO: not do that. evil.
  }
}

.nav--secondary {
  @include padding-default($grid__gutter--xs--half);

  .nav__link {
    padding: 0;
    display: inline-block;
  }
  .nav__item {
    margin-right: 1em;
  }
  .nav__item:last-child {
    margin-right: 0;
  }

  .nav__link {
    text-decoration: none;
    padding-bottom: 2px;
    &:hover {
      padding-bottom: 0;
      border-bottom: 2px solid black;
    }
  }
}

.pager {
  .pager__prev,
  .pager__next {
    img {
      width: 0.5rem;
      height: auto;
      position: relative;
      top: 0.3rem;
    }
  }
  .pager__prev { margin-right: -0.5rem }
  .pager__next { margin-left: -0.5rem }
}

@include media-query(screen-sm-max) {
  .nav--secondary.nav--horizontal ul li {
    display: block;
  }
}


// [1] overwrite default button display mode of inline-block
@include media-query(screen-sm) {
  .nav-toggle {
    display: block !important; // [1]
    margin: 0 auto;
  }
  .nav--primary {
    font-size: 3rem;
  }
  .new-project-tag {
    top: -1.7rem;
  }
  .js .nav-container .nav {
    margin-top: 7.2rem;
  }
}

@include media-query(screen-md) {
  .nav--primary,
  .nav--secondary {
    @include padding-default;
  }
}

@include media-query(screen-lg) {
  .nav--primary {
    font-size: 3.8rem;
    .nav__item {
      margin-bottom: 0.5rem;
    }
  }
  .new-project-tag {
    top: -2.4rem;
  }
}


@keyframes nav-open {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
