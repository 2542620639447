.ratio-box {
  display: block;
  width: 100%;
  height: 0;
  position: relative;
  &.r16-9 {
    padding-bottom: 56.25%;
  }
  div,
  img,
  iframe,
  video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
