video {
  &.small {
    width: 380px;
  }
  &.medium {
    width: 550px;
  }
  &.large {
    width: 768px;
  }
}